import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

function lazyLoad(view) {
  return () => import(`@/views/${view}.vue`)
}

const routes = [
  {
    path: '/',
    name: 'Index',
    component: lazyLoad("Index")
  },
  {
    path: '/about',
    redirect: '/',
  },
  {
    path: '/testimonials',
    redirect: '/',
  },
  {
    path: '/pricing',
    redirect: '/',
  },
  {
    path: '/contact',
    redirect: '/',
  },
  {
    path: '/special_projects',
    name: 'Special Projects',
    component: lazyLoad("SpecialProjects")
  },
  {
    path: '/viewProfileMembers/:code',
    name: 'ViewProfileMembers',
    component: lazyLoad("ViewProfileMembers")
  },
  {
    path: '/viewProfilePersonal/:code',
    name: 'ViewProfilePersonal',
    component: lazyLoad("ViewProfilePersonal")
  },
  {
    path: '/splprju20',
    name: 'splprju20',
    component: lazyLoad("splprju20")
  },
  {
    path: '/splprju20/:code',
    name: 'splprju20Code',
    component: lazyLoad("splprju20")
  },
  // {
  //   path: '/searchDemos/',
  //   name: 'SearchDemos',
  //   component: lazyLoad("SearchDemos")
  // },
  // {
  //   path: '/visitorProfileLink/:code',
  //   name: 'VisitorProfileLink',
  //   component: lazyLoad("VisitorProfileLink")
  // },
  {
    path: '/personal',
    component: lazyLoad("Personal/Base"),
    children: [
      {
        path: '',
        name: 'PersonalIndex',
        component: lazyLoad("Personal/Index")
      },
      {
        path: 'view_profile',
        name: 'PersonalViewProfile',
        component: lazyLoad("Personal/ViewProfile")
      },
      {
        path: 'update_profile',
        name: 'PersonalUpdateProfile',
        component: lazyLoad("Personal/UpdateProfile")
      },
      {
        path: 'subscription',
        name: 'PersonalSubscription',
        component: lazyLoad("Personal/Subscription")
      },
      {
        path: 'resources',
        name: 'PersonalResources',
        component: lazyLoad("Personal/Resources/Base"),
        children: [
          {
            path: 'talent_agency',
            name: 'ResourcesTalentAgency',
            component: lazyLoad("Personal/Resources/TalentAgency")
          },
          {
            path: 'casting_directors',
            name: 'ResourcesCastingDirectors',
            component: lazyLoad("Personal/Resources/CastingDirectors")
          },
          {
            path: 'recording_studios',
            name: 'ResourcesRecordingStudios',
            component: lazyLoad("Personal/Resources/RecordingStudios")
          },
          {
            path: 'demo_producers',
            name: 'ResourcesDemoProducers',
            component: lazyLoad("Personal/Resources/DemoProducers")
          },
          {
            path: 'voiceover_traning',
            name: 'ResourcesVoiceoverTraining',
            component: lazyLoad("Personal/Resources/VoiceoverTraining")
          },
          {
            path: 'script',
            name: 'ResourcesScript',
            component: lazyLoad("Personal/Resources/Script")
          },
          {
            path: 'union_rates',
            name: 'ResourcesUnionRates',
            component: lazyLoad("Personal/Resources/UnionRates")
          },
          {
            path: 'industry_information',
            name: 'ResourcesIndustryInformation',
            component: lazyLoad("Personal/Resources/IndustryInformation")
          },
        ]
      },
      {
        path: 'faq',
        name: 'PersonalFaq',
        component: lazyLoad("Personal/Faq")
      },
      {
        path: 'message',
        name: 'PersonalMessage',
        component: lazyLoad("Personal/Message")
      },
      {
        path: 'feedback',
        name: 'PersonalFeedback',
        component: lazyLoad("Personal/Feedback")
      },
      {
        path: 'upgrade',
        name: 'PersonalUpgrade',
        component: lazyLoad("Personal/Upgrade")
      },
    ]
  },
  {
    path: '/members',
    component: lazyLoad("Members/Base"),
    children: [
      // {
      //   path: '',
      //   name: 'MembersIndex',
      //   component: lazyLoad("Members/Index")
      // },
      {
        path: '',
        redirect: 'voiceover_projects',
      },
      {
        path: 'voiceover_projects',
        name: 'MembersVoiceoverProjects',
        component: lazyLoad("Members/VoiceoverProjects")
      },
      {
        path: 'view_submitted_auditions',
        name: 'MembersViewSubmittedAuditions',
        component: lazyLoad("Members/ViewSubmittedAuditions")
      },
      {
        path: 'view_profile',
        name: 'MembersViewProfile',
        component: lazyLoad("Members/ViewProfile")
      },
      {
        path: 'update_profile',
        name: 'MembersUpdateProfile',
        component: lazyLoad("Members/UpdateProfile")
      },
      {
        path: 'agencyMessage',
        name: 'MembersAgencyMessage',
        component: lazyLoad("Members/AgencyMessage")
      },
      {
        path: 'message',
        name: 'MembersMessage',
        component: lazyLoad("Members/Message")
      },
      {
        path: 'subscription',
        name: 'MembersSubscription',
        component: lazyLoad("Members/Subscription")
      },
      {
        path: 'resources',
        name: 'MembersResources',
        component: lazyLoad("Members/Resources/Base"),
        children: [
          {
            path: 'agent_advice',
            name: 'ResourcesAgentAdviceMembers',
            component: lazyLoad("Members/Resources/AgentAdvice")
          },
          {
            path: 'demo_producers',
            name: 'ResourcesDemoProducersMembers',
            component: lazyLoad("Members/Resources/DemoProducers")
          },
          {
            path: 'training',
            name: 'ResourcesTrainingMembers',
            component: lazyLoad("Members/Resources/Training")
          },
          {
            path: 'union_rates',
            name: 'ResourcesUnionRatesMembers',
            component: lazyLoad("Members/Resources/UnionRates")
          },
          {
            path: 'industry_information',
            name: 'ResourcesIndustryInformationMembers',
            component: lazyLoad("Members/Resources/IndustryInformation")
          },
        ]
      },
      {
        path: 'support',
        name: 'MembersSupport',
        component: lazyLoad("Members/Support")
      },
      {
        path: 'feedback',
        name: 'MembersFeedback',
        component: lazyLoad("Members/Feedback")
      },
      {
        path: 'faq',
        name: 'MembersFaq',
        component: lazyLoad("Members/Faq")
      },
    ]
  },
  {
    path: '/accounts',
    component: lazyLoad("Accounts/Base"),
    children: [
      {
        path: '',
        redirect: 'voiceover_projects',
      },
      {
        path: 'voiceover_projects',
        name: 'AccountsVoiceoverProjects',
        component: lazyLoad("Accounts/VoiceoverProjects")
      },
      {
        path: 'view_submitted_auditions',
        name: 'AccountsViewSubmittedAuditions',
        component: lazyLoad("Accounts/ViewSubmittedAuditions")
      },
      {
        path: 'bookings',
        name: 'AccountsBookings',
        component: lazyLoad("Accounts/Bookings")
      },
      {
        path: 'view_profile',
        name: 'AccountsViewProfile',
        component: lazyLoad("Accounts/ViewProfile")
      },
      {
        path: 'message',
        name: 'AccountsMessage',
        component: lazyLoad("Accounts/Message")
      },
      {
        path: 'update_profile',
        name: 'AccountsUpdateProfile',
        component: lazyLoad("Accounts/UpdateProfile")
      },
      {
        path: 'support',
        name: 'AccountSupport',
        component: lazyLoad("Accounts/Support")
      },
      {
        path: 'subscription',
        name: 'AccountsSubscription',
        component: lazyLoad("Accounts/Subscription")
      },
    ]
  },
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.afterEach((to) => {
  if (window.gtag) {
    window.gtag('js', new Date());
    window.gtag('config', 'AW-16662552839', {
      page_path: to.fullPath
    });
  }
});

export default router
