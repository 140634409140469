<script>
import moment from 'moment'
var md5 = require('md5');

import Swal from 'sweetalert2';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

let now = new Date();
let options = { timeZone: 'America/New_York' };
let currentDate = now.toLocaleString('en-US', options).split(',')[0];
export default {
  name: "MainFunc",
  data() {
    return {
      needPassword: false,
      tokenPassword: "",
      // memberData: {},
      // accountData: {},
      loading_screen: true,
      gender: [
        { "id": 1, "name": "Male" },
        { "id": 2, "name": "Female" },
      ],
      rate: [100, 150, 200, 250, 300, 350, 400, 450, 500],
      age_range: [
        { "id": "1", "name": "Preteen" },
        { "id": "2", "name": "Teen" },
        { "id": "3", "name": "18" },
        { "id": "4", "name": "20" },
        { "id": "5", "name": "25" },
        { "id": "6", "name": "30" },
        { "id": "7", "name": "35" },
        { "id": "8", "name": "40" },
        { "id": "9", "name": "45" },
        { "id": "10", "name": "50" },
        { "id": "11", "name": "55+" },
        { "id": "12", "name": "Senior" }
      ],
      union_status: [],
      delivery_cap: [],
      ethnicities: [],
      languages: [],
      countries: [],
      vocal_type: [],
      com_type: [],
      demo_cat: [],
      accents: [],

      now: currentDate,

      runningStep: false,
      currentStep: 0,
      steps: [],
    }
  },
  created() {
    // this.age_range = this.LocalStorage("_age_range_", []);
    this.union_status = this.LocalStorage("_union_status_", []);
    this.delivery_cap = this.LocalStorage("_delivery_cap_2024_", []);
    this.ethnicities = this.LocalStorage("_ethnicities_", []);
    this.languages = this.LocalStorage("_languages_", []);
    this.countries = this.LocalStorage("_countries_", []);
    this.vocal_type = this.LocalStorage("_vocal_type_", []);
    this.com_type = this.LocalStorage("_com_type_", []);
    this.demo_cat = this.LocalStorage("_demo_cat_", []);
    this.accents = this.LocalStorage("_accents_", []);
  },
  methods: {
    LocalStorage(name, set = new Object()) {
      var taskArrayString = localStorage.getItem(name);
      if (taskArrayString !== null && typeof taskArrayString === "string") {
        // console.log("Succesfully retrieved 'tasks' and contents.");
      } else {
        // console.log("Succesfully retrieved 'tasks', contents were not a string or held no contents. Contents of 'tasks' reset to empty object");
        var emptyArray = JSON.stringify(set);
        localStorage.setItem(name, emptyArray);
        taskArrayString = localStorage.getItem(name);
      }
      return JSON.parse(taskArrayString);
    },
    check_login(account) {
      if (account.m_id) {
        setTimeout(() => this.loading_screen = false, 1000);
        return true;
      } else {
        this.redirect("/");
      }
    },
    md5(val) {
      return md5(val);
    },
    redirect(path) {
      if (this.$route.path !== path) this.$router.push(path)
    },
    login_with_token(tokenData, redirect_to_project = null) {
      // let tokenData = this.LocalStorage("_tokenData_");
      // // let memberData = this.LocalStorage("_memberData_");
      // // let accountData = this.LocalStorage("_accountData_");
      // // console.log( tokenData );
      // // console.log( memberData, accountData );
      if (tokenData.email && tokenData.token && tokenData.type) {
        this.login_token(tokenData, redirect_to_project);
      }
    },
    async login_token(tokenData, redirect_to_project = null) {
      // console.log(tokenData);

      var url = this.api + 'login/token/' + this.secret;
      var data = new FormData();
      data.set('email', tokenData.email);
      data.set('token', tokenData.token);
      data.set('type', tokenData.type);
      await this.Axios.post(url, data)
        .then((response) => {
          // console.log(response.data);
          if (response.data.error == 0) {
            let acc = response.data.data;
            switch (tokenData.type) {
              case "personal_account":
                // localStorage.setItem("_accountData_",JSON.stringify(acc));
                // this.accountData = acc;
                // this.redirect("/personal");
                this.save_token(acc);
                this.redirect("/personal/update_profile");
                break;
              case "members":
                // localStorage.setItem("_memberData_",JSON.stringify(acc));
                // this.memberData = acc;
                this.save_token(acc, "_tokenData_ag_");
                this.redirect("/members");
                break;
              case "special_acct":
                this.save_token(acc, "_tokenData_sp_");
                if (redirect_to_project) {
                  this.redirect(`/accounts/voiceover_projects?p=${redirect_to_project}`);
                } else {
                  this.redirect("/accounts");
                }
                break;
            }
            // this.$swal("Successful Login ","","success");
            this.$emit("close");
          } else {
            this.needPassword = true;
          }
        })
        .catch((error) => {
          this.$swal("", "", "warning");
          console.error(error);
        })
        .then(() => {
          this.is_loading = false;
        })
    },
    save_token(data, tokenType = null) {
      let tokenData = new Object();
      tokenData.email = data.m_username;
      tokenData.m_img = data.m_img;
      tokenData.token = data.token;
      tokenData.type = data.type;
      localStorage.setItem("_tokenData_", JSON.stringify(tokenData));
      if (tokenType) localStorage.setItem(tokenType, JSON.stringify(tokenData));
    },
    set_data(data, array) {
      if (Array.isArray(data)) return data;

      let Val = [];
      let Data = data.split(",");
      Data.forEach((e) => {
        let index = array.findIndex(x => x.id == e);
        if (index !== -1) Val.push(array[index]);
      });
      return Val
    },
    update_account_data(name, array) {
      let account = this.LocalStorage(name);
      Object.keys(array).forEach((e) => {
        account[e] = array[e];
      });
      localStorage.setItem(name, JSON.stringify(account));
    },
    profile_image(path) {
      if (path == "") path = "../assets/user.jpg";

      return path;
    },
    r_com_type(company) {
      let text = company.com_type;
      if (this.com_type.length) {
        let index = this.com_type.findIndex((e) => parseInt(e.id) == parseInt(company.com_type));
        if (index > -1) return this.com_type[index].name;
      } else {
        return text;
      }
    },
    logout_personal() {
      let acct = this.sessionData;
      if (acct.type == "personal_account") {
        if (this.isFreeAccount(acct)) {
          if (acct.paypal_status !== "Approved Profiles" && !parseInt(acct.approval)) {
            this.ask_for_aprroval();
          } else {
            this.ask_for_logout();
          }
        } else {
          this.ask_for_logout();
        }
      } else {
        this.ask_for_logout();
      }

    },
    ask_for_aprroval() {
      this.$swal({
        title: "Are you sure?",
        text: "Do you want to submit your profile \n for approval before logging out?",
        icon: "info",
        closeOnClickOutside: false,
        buttons: {
          Yes: true,
          cancel: "Later",
        },
      })
        .then((value) => {
          console.log(value);
          if (value) {
            this.redirect("/personal/update_profile");
            setTimeout(() => {
              document.getElementById("approval_btn").click()
            }, 500);
          } else {
            this.redirect("/");
          }
        });
    },
    ask_for_logout() {
      this.$swal({
        title: "Are you sure?",
        text: "You want to logout?",
        icon: "warning",
        buttons: ["Stay", "Logout"],
        dangerMode: true,
      })
        .then((willDelete) => {
          if (willDelete) {
            // localStorage.removeItem("_accountData_");
            this.redirect("/");
          }
        });
    },
    uniq_id() {
      var n = Math.floor(Math.random() * 11);
      var k = Math.floor(Math.random() * 1000000);
      var m = String.fromCharCode(n) + k;
      return m;
    },
    isFreeAccount(acct = null) {
      if (!acct) {
        return false;
      } else if (acct.payment_provider == "free_account" && acct.type == "personal_account" && acct.m_img == "") {
        if (parseInt(acct.m_sex) == 1) {
          acct.m_img = "../app/personal_account/profile/maleart.png";
        } else if (parseInt(acct.m_sex) == 2) {
          acct.m_img = "../app/personal_account/profile/femaleart.png";
        }
        let tokenData = this.LocalStorage("_tokenData_");
        acct.type = tokenData.type;
        // tokenData.m_img = acct.m_img;
        this.save_token(acct);

        return true;
      }
      return (acct.payment_provider == "free_account");
    },
    isActive(acct = null) {
      if (!acct) return false;

      let active = false;
      let m_class = parseInt(acct.m_class);
      let m_pay_status = parseInt(acct.m_pay_status);
      let free_account = this.isFreeAccount(acct);

      if (free_account) {
        active = true;
      } else if (m_class == 3) {
        active = true;
      } else if (m_pay_status == 2 || m_pay_status == 5) {
        active = true;
      } else {
        const minutesToAdjust = 60 * 24;
        const millisecondsPerMinute = 60000;

        let now = this.now ?? new Date();
        let today = new Date(now + (minutesToAdjust * millisecondsPerMinute)).getTime();
        // let next_bill = moment(acct.paypal_next_bill).valueOf();
        // let m_class_exp = moment(acct.m_class_exp).valueOf();
        let payment_provider = acct.payment_provider.toLowerCase();
        console.log(payment_provider, today, moment(acct.m_class_exp).valueOf())
        switch (payment_provider) {
          case "paypal":
            // console.log(acct.paypal_next_bill,today,moment(acct.paypal_next_bill).valueOf(),today>moment(acct.paypal_next_bill).valueOf());
            if (today < moment(acct.paypal_next_bill).valueOf()) active = true;
            break;
          case "stripe":
            // console.log(acct.m_class_exp,today,moment(acct.m_class_exp).valueOf(),today>moment(acct.m_class_exp).valueOf());
            if (today < moment(acct.m_class_exp).valueOf()) active = true;
            break;
        }
      }
      return active;
    },
    r_cat_name(demo) {
      let text = demo.cat_id;
      if (this.demo_cat.length) {
        let index = this.demo_cat.findIndex((e) => parseInt(e.cat_id) == parseInt(demo.cat_id));
        if (index > -1) return this.demo_cat[index].cat_name;
      } else {
        return text;
      }
    },
    async get_age_range() {
      var url = this.api + 'dataOption/get_age_range/' + this.secret;
      var data = new FormData();
      data.set('s', this.secret);
      await this.Axios.post(url, data)
        .then((response) => {
          // console.log(response.data);
          if (response.data.error == 0) {
            this.age_range = response.data.data;
            localStorage.setItem("_age_range_", JSON.stringify(response.data.data));
          }
        })
        .catch((error) => {
          console.error(error);
        })
    },
    async get_union_status() {
      var url = this.api + 'dataOption/get_union_status/' + this.secret;
      var data = new FormData();
      data.set('s', this.secret);
      await this.Axios.post(url, data)
        .then((response) => {
          // console.log(response.data);
          if (response.data.error == 0) {
            this.union_status = response.data.data;
            localStorage.setItem("_union_status_", JSON.stringify(response.data.data));
          }
        })
        .catch((error) => {
          console.error(error);
        })
    },
    async get_delivery_cap() {
      var url = this.api + 'dataOption/get_delivery_cap/' + this.secret;
      var data = new FormData();
      data.set('s', this.secret);
      await this.Axios.post(url, data)
        .then((response) => {
          console.log(response.data);
          if (response.data.error == 0) {
            this.delivery_cap = response.data.data;
            localStorage.setItem("_delivery_cap_", JSON.stringify(response.data.data));
            localStorage.setItem("_delivery_cap_2024_", JSON.stringify(response.data.data));
          }
        })
        .catch((error) => {
          console.error(error);
        })
    },
    async get_ethnicities() {
      var url = this.api + 'dataOption/get_ethnicities/' + this.secret;
      var data = new FormData();
      data.set('s', this.secret);
      await this.Axios.post(url, data)
        .then((response) => {
          // console.log(response.data);
          if (response.data.error == 0) {
            this.ethnicities = response.data.data;
            localStorage.setItem("_ethnicities_", JSON.stringify(response.data.data));
          }
        })
        .catch((error) => {
          console.error(error);
        })
    },
    async get_languages() {
      var url = this.api + 'dataOption/get_languages/' + this.secret;
      var data = new FormData();
      data.set('s', this.secret);
      await this.Axios.post(url, data)
        .then((response) => {
          // console.log(response.data);
          if (response.data.error == 0) {
            this.languages = response.data.data;
            localStorage.setItem("_languages_", JSON.stringify(response.data.data));
          }
        })
        .catch((error) => {
          console.error(error);
        })
    },
    async get_countries() {
      var url = this.api + 'dataOption/get_countries/' + this.secret;
      var data = new FormData();
      data.set('s', this.secret);
      await this.Axios.post(url, data)
        .then((response) => {
          // console.log(response.data);
          if (response.data.error == 0) {
            this.countries = response.data.data;
            localStorage.setItem("_countries_", JSON.stringify(response.data.data));
          }
        })
        .catch((error) => {
          console.error(error);
        })
    },
    async get_vocal_type() {
      var url = this.api + 'dataOption/get_vocal_type/' + this.secret;
      var data = new FormData();
      data.set('s', this.secret);
      await this.Axios.post(url, data)
        .then((response) => {
          // console.log(response.data);
          if (response.data.error == 0) {
            this.vocal_type = response.data.data;
            localStorage.setItem("_vocal_type_", JSON.stringify(response.data.data));
          }
        })
        .catch((error) => {
          console.error(error);
        })
    },
    async get_com_type() {
      var url = this.api + 'dataOption/get_com_type/' + this.secret;
      var data = new FormData();
      data.set('s', this.secret);
      await this.Axios.post(url, data)
        .then((response) => {
          // console.log(response.data);
          if (response.data.error == 0) {
            this.com_type = response.data.data;
            localStorage.setItem("_com_type_", JSON.stringify(response.data.data));
          }
        })
        .catch((error) => {
          console.error(error);
        })
    },
    async get_demo_cat() {
      var url = this.api + 'dataOption/get_demo_cat/' + this.secret;
      var data = new FormData();
      data.set('s', this.secret);
      await this.Axios.post(url, data)
        .then((response) => {
          // console.log(response.data);
          if (response.data.error == 0) {
            this.demo_cat = response.data.data;
            localStorage.setItem("_demo_cat_", JSON.stringify(response.data.data));
          }
        })
        .catch((error) => {
          console.error(error);
        })
    },
    async get_accents() {
      var url = this.api + 'dataOption/get_accents/' + this.secret;
      var data = new FormData();
      data.set('s', this.secret);
      await this.Axios.post(url, data)
        .then((response) => {
          // console.log(response.data);
          if (response.data.error == 0) {
            this.accents = response.data.data;
            localStorage.setItem("_accents_", JSON.stringify(response.data.data));
          }
        })
        .catch((error) => {
          console.error(error);
        })
    },
    scrollToSection(id) {
      if (this.$route.name.toLowerCase() == "index") {
        setTimeout(() => {
          const element = document.getElementById(id);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }, 1);
      } else {
        this.$router.push('/').then(() => {
          this.$nextTick(() => {
            setTimeout(() => {
              const element = document.getElementById(id);
              if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
              }
            }, 1);
          });
        });
      }
    },
    playSound(src) {
      const audio = new Audio(src);
      audio.preload = 'auto';
      const playPromise = audio.play();

      if (playPromise !== undefined) {
        playPromise.catch(error => {
          console.error('Error playing sound:', error);
          // Retry playing the sound
          setTimeout(() => {
            audio.play().catch(err => {
              console.error('Retry failed:', err);
            });
          }, 1000);
        });
      }
    },
    // async startTutorial() {
    //   this.steps = [
    //     { element: document.getElementById('testimonials'), title: 'Welcome!', content: 'This is a brief tutorial to help you fill out the form.' },
    //     { element: document.getElementById('name'), title: 'Name Field', content: 'Please enter your full name in the Name field.' },
    //     { element: document.getElementById('email'), title: 'Email Field', content: 'Please enter a valid email address in the Email field.' },
    //     { element: document.getElementById('password'), title: 'Password Field', content: 'Create a strong password with at least 8 characters.' },
    //     { element: this.$refs.startButton, title: 'Submit Button', content: 'Click the Submit button to complete the form.' },
    //   ];
    //   this.currentStep = 0;
    //   this.showStep();
    // },
    async showStep() {
      if (this.steps.length === 0) return;

      const step = this.steps[this.currentStep];
      if (step.redirect) {
        if (this.$route.path !== step.redirect) {
          this.$router.push(step.redirect);
        } else {
          this.currentStep++;
          this.showStep(); // Skip the redirect step if already on the target route
        }
        return;
      }

      if (step.element) {
        this.waitForElement(step.element).then((element) => {
          // console.log('Element found:', element);
          this.scrollToElement(element).then(() => {
            const instance = this.createTippyInstance(element, step);
            instance.show();
          });
        }).catch(error => {
          console.error('Error waiting for element:', error);
        });
      } else {
        // Create a default placeholder element for Tippy to attach to
        const placeholderElement = this.createPlaceholderElement();
        const instance = this.createTippyInstance(placeholderElement, step, false);
        instance.show();
      }
    },
    createTippyInstance(element, step, showArrow = true) {
      return tippy(element, {
        content: `
    <div class="tippy-content">
      <h3>${step.title}</h3>
      <p>${step.content}</p>
      <div class="tippy-buttons">
        ${this.currentStep > 0 ? '<button type="button" id="prevButton" class="button is-small">Prev</button>' : ''}
        ${this.currentStep < this.steps.length - 1 ? '<button type="button" id="nextButton" class="button is-small">Next</button>' : '<button type="button" id="doneButton" class="button is-small">Done</button>'}
      </div>
    </div>
  `,
        allowHTML: true,
        arrow: showArrow,
        theme: 'light',
        trigger: 'manual',
        placement: 'top',
        animation: 'scale-extreme',
        interactive: true,
        hideOnClick: false, // Prevent hiding on outside click
        onShow: (instance) => {
          setTimeout(() => {
            if (this.currentStep === this.steps.length - 1) {
              document.getElementById('doneButton').addEventListener('click', () => this.endTutorial(instance));
            } else {
              document.getElementById('nextButton').addEventListener('click', () => this.nextStep(instance));
            }
            if (this.currentStep > 0) {
              document.getElementById('prevButton').addEventListener('click', () => this.prevStep(instance));
            }
          }, 0);
        },
      });
    },
    waitForElement(selector) {
      return new Promise((resolve, reject) => {
        const checkElement = () => {
          const element = document.getElementById(selector) || this.$refs[selector];
          if (element) {
            // console.log('Element found:', selector);
            resolve(element);
            return true;
          }
          return false;
        };

        if (!checkElement()) {
          const observer = new MutationObserver(() => {
            if (checkElement()) {
              observer.disconnect();
            }
          });

          observer.observe(document.body, {
            childList: true,
            subtree: true,
          });

          // Retry mechanism with interval
          let attempts = 0;
          const maxAttempts = 20; // Adjust this number for more retries
          const interval = setInterval(() => {
            if (checkElement() || attempts >= maxAttempts) {
              clearInterval(interval);
              if (attempts >= maxAttempts) {
                reject(new Error('Element not found: ' + selector));
              }
            }
            attempts++;
          }, 100); // Check every 500 milliseconds
        }
      });
    },
    validateStep(step) {
      if (step.condition && !step.condition()) {
        Swal.fire({
          icon: 'error',
          title: `${step.alert}`,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
        });
        return false;
      }
      return true;
    },
    nextStep(instance) {
      const step = this.steps[this.currentStep];
      if (!this.validateStep(step)) {
        return; // Stop if validation fails
      }
      instance.hide(); // Hide the current tooltip
      this.currentStep++;
      this.showStep(); // Show the next step
    },
    prevStep(instance) {
      instance.destroy();
      this.currentStep--;
      this.showStep();
    },
    endTutorial(instance) {
      instance.destroy();
      this.currentStep = 0;
      this.runningStep = false;
      this.afterTutorialFinished();
    },
    scrollToElement(element) {
      return new Promise((resolve) => {
        element.scrollIntoView({ behavior: 'smooth' });
        setTimeout(() => {
          resolve();
        }, 500);
      });
    },
    createPlaceholderElement() {
      const placeholder = document.createElement('div');
      placeholder.style.position = 'fixed';
      placeholder.style.top = '40%';
      placeholder.style.left = '45%';
      placeholder.style.transform = 'translate(-50%, -50%)';
      placeholder.style.zIndex = 9999; // Ensure it's above other elements
      document.body.appendChild(placeholder);
      return placeholder;
    },
  },
  watch: {
    // '$route.name'() {
    //   if (this.runningStep) {
    //     setTimeout(() => {
    //       this.showStep();
    //     }, 1000);
    //   }
    // },
  },
  filters: {
    moment(date) {
      return moment(date).format('Do MMMM YYYY');
    },
    date_message: function (date) {
      return moment(date).format('D MMM, YYYY h:mm a');
    },
    date_audition: function (date) {
      return moment(date).format('D MMM, YYYY h:mm a') + " (PST)";
    },
    date_project: function (date) {
      return moment(date).format('MMMM Do, YYYY h:mm a') + " (PST)";
    },
    date_public: function (date) {
      return moment(date).format('MMMM Do, YYYY ') + " (PST)";
    },
    date_list: function (date) {
      var fromNow = moment(date).fromNow();
      return moment(date).calendar(null, {
        lastWeek: '[Last] dddd',
        lastDay: '[Yesterday]',
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        nextWeek: 'dddd',
        sameElse: function () {
          return "[" + fromNow + "]";
        }
      });
    },
    highlight: function (words, query) {
      return words.replace(query, '<span class="highlight">' + query + '</span>')
    },
    dateMessage(value) {
      if (!value) return '';

      const date = new Date(value);

      const hours = date.getHours();
      const minutes = date.getMinutes();
      const ampm = hours >= 12 ? 'PM' : 'AM';
      const displayHours = hours % 12 || 12;
      const displayMinutes = minutes < 10 ? '0' + minutes : minutes;

      return `${displayHours}:${displayMinutes} ${ampm}`;
    },
    dayMessage(value) {
      if (!value) return '';

      const date = new Date(value);
      const now = new Date();

      const diffTime = now.getTime() - date.getTime();
      const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

      let dayString;
      if (diffDays === 0) {
        dayString = 'today';
      } else if (diffDays === 1) {
        dayString = 'yesterday';
      } else {
        dayString = `${diffDays} days ago`;
      }

      return `${dayString}`;
    }
  }
};
</script>

<style>
.tippy-content {
  text-align: left;
  padding: 10px 8px;
  z-index: 1000;
}

.tippy-content h3 {
  font-size: 16px !important;
  margin-bottom: 10px;
}

.tippy-content h3,
.tippy-content p {
  color: #fff !important;
}

.tippy-buttons {
  margin-top: 10px;
  text-align: right;
}

.tippy-buttons button {
  margin-left: 10px;
  padding: 5px 10px;
}
</style>