<script>
export default {
  data() {
    return {
      // "id_name_on_database":"label_on_website"
      stripe_plan_name: {
        "vocadd-monthly": "Monthly",
        "vocadd-semiannual": "Semi-Annual",
        "vocadd-annual": "Yearly",
        "vocadd-personal-monthly": "Personal Monthly",
        "vocadd-personal-yearly": "Personal Yearly",

        "price_1Kxuc0KkrZDt46Lh3oyG1fbp": "Voicecast Networks Monthly",
        "price_1Kxuf8KkrZDt46LhtGzsB5Dq": "Voicecast Networks Quarterly",
        "price_1KxuguKkrZDt46LhRTyk39Vd": "Voicecast Networks Semi-Annual",
        "price_1KxuhcKkrZDt46LhiImj4Clj": "Voicecast Networks Yearly",

        "price_1PRA6iKkrZDt46LhBgTNRQkR": "Monthly Subscription",
        "price_1PERwhKkrZDt46Lh8vABGOcD": "Semi-Annual Subscription",
        "price_1PERx9KkrZDt46LhWEUdhPPo": "Yearly Subscription",
      },
      stripe_plans: [],
    }
  },
};
</script>
