// src/font-awesome.js
import { library, dom } from '@fortawesome/fontawesome-svg-core';

// Dynamically import icons
const loadIcons = async () => {
    const solidIcons = await import('@fortawesome/free-solid-svg-icons');
    const solidIconList = Object.keys(solidIcons)
        .filter(key => key !== 'fas' && key !== 'prefix')
        .map(icon => { /*console.log(icon);*/ return solidIcons[icon] });
    library.add(...solidIconList);
    dom.watch();
};

loadIcons();